import styled from "styled-components";

import { AppColors, AppColorsTitle } from "config/styles/colors.config";
import { IStarBackgroundBar } from "./StartBackgroundBars.component.model";

export const StyledBar = styled.span.attrs<IStarBackgroundBar>(starBackgroundBar => ({
  style: {
    left: starBackgroundBar.$position.x,
    top: starBackgroundBar.$position.y,
    width: starBackgroundBar.width,
    transform: `rotate(${starBackgroundBar.$rotation}deg)`,
    backgroundColor: AppColors.getColorByTitle(AppColorsTitle.Green).value,
  }
}))`
  position: fixed;
  height: 1px;
  transform-origin: left;
  opacity: 0.3;
  z-index: -1;

  &::after {
    content: "";
    position: absolute;
    top: -0.5px; left: -0.5px;
    width: 2px; height: 2px;
    background-color: white;
  }
`
