import { random, sortBy, uniqueId } from 'lodash';

import { Position } from 'models/Point.model';
import { AppColors } from 'config/styles/colors.config';

import { StarBackgroundPointModel } from './StarBackgroundPoints/StartBackgroundPoints.component.model';
import { StarBackgroundBarModel } from './StarBackgroundBars/StartBackgroundBars.component.model';

export class StarBackgroundModel {
  static readonly starBackgroundPointsVolume = 5000;
  static readonly starBackgroundPointsAmount = Math.floor(
    (window.innerHeight * window.innerWidth) / StarBackgroundModel.starBackgroundPointsVolume
  );

  static readonly starBackgroundBarsAmount = 5;

  public static createPoints () {
    const randomMousePosition = Position.generateRandom();
    return new Array(StarBackgroundModel.starBackgroundPointsAmount).fill(0).map(() =>
      new StarBackgroundPointModel({
        id: uniqueId(),
        $position: Position.generateRandom(),
        opacity: random(0.6, 1),
        color: AppColors.getRandomColor().value,
      }, randomMousePosition)
    );
  }

  public static getMouseClosestPoints (points: StarBackgroundPointModel[], mousePosition: Position): StarBackgroundPointModel[] {
    const pointsWithMouseDistance: StarBackgroundPointModel[] = points.map(point =>
      point.getCurrentPositionInScreen(mousePosition)
    );

    const pointSortedByDistance = sortBy(pointsWithMouseDistance, 'mouseDistance');
    const closestPoints = pointSortedByDistance.filter((_, i) => i < StarBackgroundModel.starBackgroundBarsAmount);

    return closestPoints;
  }

  public static createBarsBetweenMouseAndPoints (starBackgroundPoints: StarBackgroundPointModel[], mousePosition: Position): StarBackgroundBarModel[] {
    return starBackgroundPoints.map(starBackgroundPoint =>
      new StarBackgroundBarModel({
        $position: starBackgroundPoint.$position,
        $rotation: starBackgroundPoint.$position.calcDegreeRotationBetweenOtherPosition(mousePosition),
        width: starBackgroundPoint.mouseDistance,
      })
    );
  }
}
