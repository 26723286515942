import styled from 'styled-components'

import { AppBreakpoints, AppDevices } from 'config/styles/breakpoints.config'
import { AppColors, AppColorsTitle } from 'config/styles/colors.config'

export const StyledMainHeadline = styled.section`
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledMainHeadlineHello = styled.h1`
  font-size: max(3rem, 8vw);
  color: ${AppColors.getColorByTitle(AppColorsTitle.Green).value};

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
    text-wrap: nowrap;
    font-size: 6.5vw;
  }
`

export const StyledMainHeadlineName = styled.h3`
  font-weight: 700;
  font-size: max(1.75rem, 5vw);
  line-height: max(2rem, 5vw);
  color: ${AppColors.getColorByTitle(AppColorsTitle.White).value};
  margin-bottom: 1rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
    font-size: 4.25vw;
    line-height: 4.25vw;
    margin-bottom: 0rem;
    text-wrap: nowrap;
  }
`

export const StyledMainHeadlineArea = styled.div`
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 1px ${AppColors.getColorByTitle(AppColorsTitle.Green).value};
`

export const StyledMainHeadlineHelloContainer = styled.div`
`;

export const StyledMainHeadlineBrowserContainer = styled.div`
  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
    flex: 1;

    & > div {
      width: 110%;
      margin-left: -10%;
    }
  }
`;
