import { random } from "lodash";

import { BaseClass } from "utils/baseclass";

interface IPosition {
  readonly x: number;
  readonly y: number;
}

export class Position extends BaseClass<IPosition>() {
  static generateRandom () {
    return new Position({
      x: random(0, window.innerWidth),
      y: random(0, window.innerHeight),
    });
  }

  public calcDistanteBetweenOtherPosition (position: Position) {
    return Math.sqrt(
      Math.pow(this.x - position.x, 2) +
      Math.pow(this.y - position.y, 2)
    )
  }

  public calcDegreeRotationBetweenOtherPosition (position: Position) {
    const delta_x = this.x - position.x;
    const delta_y = this.y - position.y;
    return Math.atan2(delta_y, delta_x) * (180 / Math.PI) - 180;
  }
}
