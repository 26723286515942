import React from 'react'

import { StyledMain } from './Main.screen.styles'

import { MainWorkHistory } from './WorkHistory/MainWorkHistory.component'
import { MainHeadlineComponent } from './Headline/MainHeadline.component'

export function MainScreen () {
  return (
    <StyledMain>
      <MainHeadlineComponent />
      <MainWorkHistory />
    </StyledMain>
  )
}
