import styled from 'styled-components'

export const StyledSocialIcon = styled.a`
  display: inline-flex;
`

export const StyledSocialIconImg = styled.img`
  width: max(20px, 1.5vw);
  height: max(20px, 1.5vw);
  cursor: pointer;
`
