import React from "react";

import {
  MainAboutMeClassContainer,
  MainAboutMeClassText,
  MainAboutMeContainer,
  MainAboutMeKeyText,
  MainAboutMeKeyValueContainer,
  MainAboutMeObjectContainer,
  MainAboutMeValue
} from "./MainAboutMe.component.styles";
import { useTranslation } from "react-i18next";

const ABOUT_ME_DATA = [
  {
    title: 'main.about.me.title',
    values: [
      {
        key: 'main.about.me.profession.key',
        value: 'main.about.me.profession.value'
      },
      {
        key: 'main.about.me.experience.key',
        value: 'main.about.me.experience.value'
      },
      {
        key: 'main.about.me.location.key',
        value: 'main.about.me.location.value'
      }
    ]
  },
  {
    title: 'main.about.stack.title',
    values: [
      {
        key: 'main.about.stack.frontend.key',
        value: 'main.about.stack.frontend.value'
      },
      {
        key: 'main.about.stack.backend.key',
        value: 'main.about.stack.backend.value'
      },
      {
        key: 'main.about.stack.mobile.key',
        value: 'main.about.stack.mobile.value'
      },
      {
        key: 'main.about.stack.other.key',
        value: 'main.about.stack.other.value'
      }
    ]
  }
]

export function MainAboutMeComponent () {
  const { t } = useTranslation();

  return (
    <MainAboutMeContainer>
      {ABOUT_ME_DATA.map(({ title, values }) => (
        <MainAboutMeClassContainer key={title}>
          <MainAboutMeClassText>
            .{t(title)} &nbsp;

            <MainAboutMeValue>
              {`{`}
            </MainAboutMeValue>
          </MainAboutMeClassText>

          <MainAboutMeObjectContainer>
            {values.map(({ key, value }) => (
              <MainAboutMeKeyValueContainer key={key}>
                <MainAboutMeKeyText>
                  "{t(key)}": &nbsp;
                </MainAboutMeKeyText>

                <MainAboutMeValue>
                  "{t(value)}",
                </MainAboutMeValue>
              </MainAboutMeKeyValueContainer>
            ))}
          </MainAboutMeObjectContainer>

          <MainAboutMeValue>
            {`}`}
          </MainAboutMeValue>
        </MainAboutMeClassContainer>
      ))}
    </MainAboutMeContainer>
  )
}
