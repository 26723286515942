import panda from "assets/images/work_experience/panda.jpg";
import cit from "assets/images/work_experience/cit.jpg";
import vizir from "assets/images/work_experience/vizir.jpg";
import questrade from "assets/images/work_experience/questrade.jpg";

export const WORK_HISTORY_DATA = [
  {
    image: panda,
    stack: ["jQuery", "React", "React Native"],

    en: {
      from: "Feb. 2018",
      to: "Aug. 2019",
      title: "The Aubergine Panda",
      location: "São Paulo - Brazil",
      role: "Front-End Developer",
      description: "My first job, as an intern, I was hired to build marketing emails using HTML and CSS, after that, I started to participate in projects to program more complex web systems, initially with jQuery, then with React or React Native, depending on demand.",
    },

    pt: {
      from: "Fev. 2018",
      to: "Ago. 2019",
      title: "The Aubergine Panda",
      location: "São Paulo - Brasil",
      role: "Desenvolvedor Front-End",
      description: "Meu primeiro emprego, como estágiario, entrei para construir emails marketing usando HTML e CSS, após isso, comecei a participar de projetos para programar sistemas mais complexos para a web, inicialmente com jQuery, depois com React ou React Native, conforme a demanda.",
    }
  },

  {
    image: cit,
    stack: ["Angular", "Java", "SQL"],

    en: {
      from: "Aug. 2019",
      to: "Dec. 2019",
      title: "Senac University Center (CIT)",
      location: "São Paulo - Brazil",
      role: "Web Developer",
      description: "I worked on new features and adjustments to a legacy application, written in Angular, Java and OracleSQL.",
    },

    pt: {
      from: "Ago. 2019",
      to: "Dez. 2019",
      title: "Centro Universitário SENAC (CIT)",
      location: "São Paulo - Brasil",
      role: "Desenvolvedor Web",
      description: "Trabalhei em features novas e ajustes de uma aplicação legada, escrita em Angular, Java e OracleSQL.",
    }
  },

  {
    image: vizir,
    stack: ["React", "React Native", "Node", "Typescript"],

    en: {
      from: "Feb. 2020",
      to: "Mar. 2022",
      title: "Vizir Software Studio",
      location: "São Paulo - Brazil",
      role: "Software Engineer",
      description: "I started maintaining a project in React Native and developing a small application in React, after that, I took on the leadership of the Front-End in a project that lasted +1 year, in addition to cooperating in the Back-End, when the project finished, I went to work on smaller features on an international project, to practice my English.",
    },

    pt: {
      from: "Fev. 2020",
      to: "Mar. 2022",
      title: "Vizir Software Studio",
      location: "São Paulo - Brasil",
      role: "Engenheiro de Software",
      description: "Entrei fazendo a manutenção de um projeto em React Native e desenvolver uma aplicação de pequeno porte em React, após isso, assumi a liderança do Front-End em um projeto que durou +1 ano, além de cooperar no Back-End, quando as demandas acabaram, fui trabalhar em features menores em um projeto internacional para praticar meu inglês.",
    }
  },

  {
    image: questrade,
    stack: ["Node", "Typescript", "C#", "GCP"],

    en: {
      from: "Feb. 2020",
      to: "Today",
      title: "Questrade Financial Group",
      location: "Toronto - Canada",
      role: "Software Engineer",
      description: "This canadian fintech bought the software house I was working for, as the part of the migration I left Front-End to try a career as a Data Engineer, which I ended up not adapting to. I currently work on Back-End applications focusing on data.",
    },

    pt: {
      from: "Dez. 2020",
      to: "Hoje",
      title: "Questrade",
      location: "Toronto - Canada",
      role: "Engenheiro de Software",
      description: "Essa fintech canadense comprou a software house em que eu trabalhava, como parte da migração eu deixei o Front-End para tentar uma carreira como Engenheiro de Dados, a qual acabei não me adaptando, atualmente trabalho em aplicações Back-End com foco em dados.",
    }
  }
]
