import React from "react";
import { useTranslation } from 'react-i18next';

import { BrowserBoxComponent } from "components/BrowserBox/BrowserBox.component";

import {
  StyledMainWorkHistory,
  StyledMainWorkHistoryContainer,
  StyledMainWorkHistoryTitle,
  StyledMainWorkHistoryItem,
  StyledMainWorkHistoryItemContent,
  StyledMainWorkHistoryItemContentImg,
  StyledWorkHistoryTagsContainer,
  StyledWorkHistoryTag,
  StyledWorkHistoryButton,
  StyledWorkHistoryButtonArrow,
} from "./MainWorkHistory.component.styles";

import { WORK_HISTORY_DATA } from "repositories/workHistoriy.repository";

export function MainWorkHistory () {
  const { i18n, t } = useTranslation(); 


  return (
    <StyledMainWorkHistory>
      <StyledMainWorkHistoryTitle>
        Work History
      </StyledMainWorkHistoryTitle>

      <StyledMainWorkHistoryContainer>
        {WORK_HISTORY_DATA.map(work => (
          <StyledMainWorkHistoryItem key={work[i18n.resolvedLanguage].title}>
            <BrowserBoxComponent faded>
              <StyledMainWorkHistoryItemContent>
                <h5> {work[i18n.resolvedLanguage].title}</h5>

                <small>
                  <b>
                    {work[i18n.resolvedLanguage].role}
                  </b>
                </small>

                <small>
                  {work[i18n.resolvedLanguage].from} - {work[i18n.resolvedLanguage].to}
                </small>

                <StyledMainWorkHistoryItemContentImg $img={work.image} />

                <p>
                  {work[i18n.resolvedLanguage].description}
                </p>

                <StyledWorkHistoryTagsContainer>
                  {work.stack.map(s => (
                    <StyledWorkHistoryTag key={s}>
                      {s}
                    </StyledWorkHistoryTag>
                  ))}
                </StyledWorkHistoryTagsContainer>

                <StyledWorkHistoryButton>
                  {t('main.about.workHistory.seeProjects')}
                  <StyledWorkHistoryButtonArrow />
                </StyledWorkHistoryButton>
              </StyledMainWorkHistoryItemContent>
            </BrowserBoxComponent>
          </StyledMainWorkHistoryItem>
        ))}
      </StyledMainWorkHistoryContainer>
    </StyledMainWorkHistory>
  )
}
