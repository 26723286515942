import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  StyledMainHeadlineArea,
  StyledMainHeadlineHello,
  StyledMainHeadlineHelloContainer,
  StyledMainHeadlineBrowserContainer,
  StyledMainHeadline,
  StyledMainHeadlineName
} from "./MainHeadline.component.styles";

import { BrowserBoxComponent } from 'components/BrowserBox/BrowserBox.component';
import { MainAboutMeComponent } from './AboutMe/MainAboutMe.component';

export function MainHeadlineComponent () {
  const { t } = useTranslation()

  return (
    <StyledMainHeadline>
      <StyledMainHeadlineHelloContainer>
        <StyledMainHeadlineHello>
          {t('main.hello')}
        </StyledMainHeadlineHello>

        <StyledMainHeadlineName>
          {t('main.am')}
          <br />
          <StyledMainHeadlineArea>
            {t('main.front')}
          </StyledMainHeadlineArea>
        </StyledMainHeadlineName>
      </StyledMainHeadlineHelloContainer>

      <StyledMainHeadlineBrowserContainer>
        <BrowserBoxComponent title='vcosta.me' faded={false}>
          <MainAboutMeComponent />
        </BrowserBoxComponent>
      </StyledMainHeadlineBrowserContainer>
    </StyledMainHeadline>
  )
}
