import React from 'react'

import { SocialIcon } from 'components/SocialIcon/SocialIcon.component'

import linkedin from 'assets/images/icons/linkedin.png'
import github from 'assets/images/icons/github.png'

import { StyledNav } from './Navbar.component.styles'

export function Navbar () {
  return (
    <StyledNav>
      <SocialIcon
        image={linkedin}
        title='linkedin'
        href='https://www.linkedin.com/in/victor-silva-costa'
      />

      <SocialIcon
        image={github}
        title='github'
        href='https://github.com/victorS7P'
      />
    </StyledNav>
  )
}
