import React, { useEffect, useMemo, useState } from 'react';

import { useMousePosition } from 'hooks/useMousePosition.hook';

import { StarBackgroundBars } from "./StarBackgroundBars/StartBackgroundBars.component";
import { StarBackgroundPoints } from "./StarBackgroundPoints/StartBackgroundPoints.component";

import { StyledStarBackground } from './StarBackground.component.styles';
import { StarBackgroundModel } from './StarBackground.component.model';

export function StarBackground () : React.ReactElement {
  const [pointsInWindow, setPointsInWindow] = useState(StarBackgroundModel.createPoints());

  const mousePosition = useMousePosition();
  const [mouseClosestPoints, setMouseClosesPoints] = useState(
    StarBackgroundModel.getMouseClosestPoints(pointsInWindow, mousePosition)
  );

  const bars = useMemo(() =>
    StarBackgroundModel.createBarsBetweenMouseAndPoints(mouseClosestPoints, mousePosition),
  [mouseClosestPoints, mousePosition]);

  useEffect(
    () => {
    setMouseClosesPoints(StarBackgroundModel.getMouseClosestPoints(pointsInWindow, mousePosition));

      const updateClosestPointsPosition = setInterval(() => {
        setMouseClosesPoints(StarBackgroundModel.getMouseClosestPoints(pointsInWindow, mousePosition));
      }, 10);

      return () => {
        clearInterval(updateClosestPointsPosition);
      }
    },
  [pointsInWindow, mousePosition])

  useEffect(() => {
    const recreatePointsWhenResize = () => setPointsInWindow(StarBackgroundModel.createPoints());
    window.addEventListener('resize', recreatePointsWhenResize);

    return () => {
      window.removeEventListener('resize', recreatePointsWhenResize);
    };
  });

  return (
    <StyledStarBackground>
      <StarBackgroundPoints points={pointsInWindow} />
      <StarBackgroundBars   bars={bars} />
    </StyledStarBackground>
  )
}
