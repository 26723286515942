export enum AppDevices {
  MobileS = "MobileS",
  MobileM = "MobileM",
  MobileL = "MobileL",
  Tablet = "Tablet",
  Laptop = "Laptop",
  LaptopL = "LaptopL",
  Desktop = "Desktop",
}

type Breakpoints<T> = {
  [key in AppDevices]: T;
}

export class AppBreakpoints {
  private static _sizes: Breakpoints<string> = {
    MobileS: `${320 / 16}rem`,
    MobileM: `${375 / 16}rem`,
    MobileL: `${425 / 16}rem`,
    Tablet: `${768 / 16}rem`,
    Laptop: `${1024 / 16}rem`,
    LaptopL: `${1448 / 16}rem`,
    Desktop: `${2560 / 16}rem`
  };

  private static _createDeviceMinWidth(device: AppDevices): string {
    return `(min-width: ${this._sizes[device]})`;
  }

  private static _devices: Breakpoints<string> = {
    MobileS: this._createDeviceMinWidth(AppDevices.MobileS),
    MobileM: this._createDeviceMinWidth(AppDevices.MobileM),
    MobileL: this._createDeviceMinWidth(AppDevices.MobileL),
    Tablet: this._createDeviceMinWidth(AppDevices.Tablet),
    Laptop: this._createDeviceMinWidth(AppDevices.Laptop),
    LaptopL: this._createDeviceMinWidth(AppDevices.LaptopL),
    Desktop: this._createDeviceMinWidth(AppDevices.Desktop),
  };

  public static setStyleForBreakpoint(device: AppDevices): string {
    return `@media ${AppBreakpoints._devices[device]}`;
  }
}
