import styled from "styled-components";
import { AppBreakpoints, AppDevices } from "config/styles/breakpoints.config";

export const MainAboutMeContainer = styled.div`
  height: 100%;
  margin: 1rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
    margin-left: 3rem;
  }
`;

export const MainAboutMeClassContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
`;

export const MainAboutMeClassText = styled.span`
  color: #C8A3D4;
  font-size: 0.8rem;
`;

export const MainAboutMeObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  font-size: 0.8rem;
  line-height: 1.2rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Tablet)} {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
`;

export const MainAboutMeKeyValueContainer = styled.div`
`;

export const MainAboutMeKeyText = styled.span`
  color: #0EE6B7;
`;

export const MainAboutMeValue = styled.span`
  color: white;
`;
