import React, { PropsWithChildren } from "react";

import { IBrowserBox } from "./BrowserBox.component.model";

import {
  StyledBrowserBoxContainer,
  StyledBrowserBoxTopBar,
  StyledBrowserBoxTopBarButton,
  StyledBrowserBoxTopBarButtonsContainer,
  StyledBrowserBoxTopBarContent,
  StyledBrowserBoxTopBarTitle
} from "./BrowserBox.component.styles";

export function BrowserBoxComponent (props: PropsWithChildren<IBrowserBox>): React.ReactNode {
  return (
    <StyledBrowserBoxContainer $faded={props.faded}>
      <StyledBrowserBoxTopBar>
        <StyledBrowserBoxTopBarButtonsContainer>
          <StyledBrowserBoxTopBarButton />
          <StyledBrowserBoxTopBarButton />
          <StyledBrowserBoxTopBarButton />
        </StyledBrowserBoxTopBarButtonsContainer>

        {props.title?.length > 0 ? (
          <StyledBrowserBoxTopBarTitle>
            {props.title}
          </StyledBrowserBoxTopBarTitle>
        ) : null}
      </StyledBrowserBoxTopBar>

      <StyledBrowserBoxTopBarContent>
        {props.children}
      </StyledBrowserBoxTopBarContent>
    </StyledBrowserBoxContainer>
  )
}
