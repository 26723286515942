import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppLanguages } from 'config/i18n/i18n.config';

import {
  StyledActiveLanguageImg,
  StyledLanguageImg,
  StyledLanguageSelectorBox,
  StyledLanguageSelectorWrapper
} from './LanguageSelector.component.styles';

export function LanguageSelector () {
  const { i18n } = useTranslation();
  const selectedLanguage = AppLanguages.findByTytle(i18n.resolvedLanguage);

  return (
    <StyledLanguageSelectorWrapper>
      <StyledLanguageSelectorBox>
        {AppLanguages.languages.map(({ title, icon }) => (
          <StyledLanguageImg
            src={icon}
            key={`language-${title}`}
            alt={`${title}-lang-select`}
            onClick={() => i18n.changeLanguage(title)}
          />
        ))}
      </StyledLanguageSelectorBox>

      <StyledActiveLanguageImg
        src={selectedLanguage.icon}
        alt={`${i18n.resolvedLanguage}-active-language`}
      />
    </StyledLanguageSelectorWrapper>
  )
}
