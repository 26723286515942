import { Position } from "models/Point.model";
import { BaseClass } from "utils/baseclass";

export interface IStarBackgroundPoint {
  $position: Position;
  id: string;
  color: string;
  opacity: number;
}

export class StarBackgroundPointModel extends BaseClass<IStarBackgroundPoint>() {
  public mouseDistance: number;

  constructor(data: IStarBackgroundPoint, mouse: Position) {
    super(data);
    this.setMouseDistance(mouse);
  }

  private setMouseDistance (mousePosition: Position): void {
    this.mouseDistance = this.$position.calcDistanteBetweenOtherPosition(mousePosition);
  }

  public getCurrentPositionInScreen (mousePosition: Position): StarBackgroundPointModel {
    const pointElement = document.getElementById(this.id);

    if (pointElement) {
      const pointElementPosition = document.getElementById(this.id).getBoundingClientRect();
      this.$position = new Position({ x: pointElementPosition.left, y: pointElementPosition.top });
      this.setMouseDistance(mousePosition);
    }

    return this;
  }
}
