import enIcon from 'assets/images/icons/flags/en.png'
import { Language } from "interfaces/Language.interface";

export const en: Language = {
  title: 'en',
  icon: enIcon,
  translation: {
    main: {
      hello: 'Hello!',
      am: 'I\'m Victor Costa,',
      front: 'Fullstack Developer.',
      about: {
        me: {
          title: 'aboutMe',
          profession: {
            key: 'profession',
            value: 'Fullstack Developer'
          },
          experience: {
            key: 'yearsOfExperience',
            value: '6+'
          },
          location: {
            key: 'location',
            value: 'Brazil'
          }
        },
        stack: {
          title: 'preferredStack',
          frontend: {
            key: 'frontEnd',
            value: 'React + Typescript'
          },
          backend: {
            key: 'backEnd',
            value: 'NodeJS + Typescript'
          },
          mobile: {
            key: 'mobile',
            value: 'React Native'
          },
          other: {
            key: 'otherTools',
            value: 'MySQL, MongoDB, GCP, Docker'
          }
        },
        workHistory: {
          seeProjects: 'See projects'
        }
      }
    },
  }
}
