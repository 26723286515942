import styled from "styled-components"

export const StyledLanguageImg = styled.img`
  width: max(32px, 2vw);
  height: max(32px, 2vw);
`

export const StyledActiveLanguageImg = styled(StyledLanguageImg)`
  border-radius: 0.5em;
  cursor: default;
  opacity: 0.3;
  transition: all .3s ease;
`

export const StyledLanguageSelectorBox = styled.div`
  border-radius: 0.3em;
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(30px);
  margin-bottom: 4px;

  ${StyledLanguageImg} {
    width: max(24px, 1.5vw);
    height: max(24px, 1.5vw);
    margin: max(4px, 0.1vw);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`

export const StyledLanguageSelectorWrapper = styled.div`
  position: fixed;
  bottom: max(1rem, 1vw);
  right: max(1rem, 1vw);
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &:hover {
    ${StyledActiveLanguageImg} {
      opacity: 1;
    }

    ${StyledLanguageSelectorBox} {
      opacity: 1;
      transform: none;
    }
  }
`
