import brIcon from 'assets/images/icons/flags/br.png'
import { Language } from "interfaces/Language.interface";

export const pt: Language = {
  title: 'pt',
  icon: brIcon,
  translation: {
    main: {
      hello: 'Olá!',
      am: 'Sou Victor Costa,',
      front: 'Desenvolvedor Fullstack.',
      about: {
        me: {
          title: 'sobreMim',
          profession: {
            key: 'profissão',
            value: 'Desenvolvedor Fullstack'
          },
          experience: {
            key: 'anosDeExperiencia',
            value: '6+'
          },
          location: {
            key: 'Localização',
            value: 'Brasil'
          }
        },
        stack: {
          title: 'stackPreferida',
          frontend: {
            key: 'frontEnd',
            value: 'React + Typescript'
          },
          backend: {
            key: 'backEnd',
            value: 'NodeJS + Typescript'
          },
          mobile: {
            key: 'mobile',
            value: 'React Native'
          },
          other: {
            key: 'outrasFerramentas',
            value: 'MySQL, MongoDB, GCP, Docker'
          }
        },
        workHistory: {
          seeProjects: 'Ver projetos'
        }
      }
    },
  }
}
