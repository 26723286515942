import React from 'react'

import { StyledSocialIcon, StyledSocialIconImg } from './SocialIcon.component.styles'

export function SocialIcon ({ image, href, title }) {
  return (
    <StyledSocialIcon
      href={href}
      target='_blank'
      rel="noreferrer"
    >
      <StyledSocialIconImg
        src={image}
        alt={title}
      />
    </StyledSocialIcon>
  )
}
