import React from "react";

import { StyledBar } from "./StartBackgroundBars.component.styles";
import { StarBackgroundBarModel } from "./StartBackgroundBars.component.model";

export const StarBackgroundBars = React.memo<{ bars: StarBackgroundBarModel[] }>(
  function ({ bars }) {
    function renderBar(starBackgroundBar: StarBackgroundBarModel) {
      return (
        <StyledBar
          key={`bar-${starBackgroundBar.id}`}
          $position={starBackgroundBar.$position}
          $rotation={starBackgroundBar.$rotation}
          width={starBackgroundBar.width}
        />
      )
    }

    return (
      <>
        {bars.map(renderBar)}
      </>
    )
  }
)
