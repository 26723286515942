import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Language } from 'interfaces/Language.interface'

import { en } from './i18n-en.config'
import { pt } from './i18n-pt.config'

export class AppLanguages {
  static languages: Language[] = [pt, en]

  static getLanguagesAsi18nResources () {
    return AppLanguages.languages.reduce(
      (prev, cur: Language) => ({ ...prev, [cur.title]: cur }), {}
    )
  }

  static findByTytle (title: string) {
    return AppLanguages.languages.find(language => language.title === title);
  }
}

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: en.title,
    interpolation: { escapeValue: false },
    resources: AppLanguages.getLanguagesAsi18nResources()
  })

export { i18n }