import React from "react";
import { random } from "lodash";

import { StyledPoint } from "./StartBackgroundPoints.component.styles";
import { StarBackgroundPointModel } from "./StartBackgroundPoints.component.model";

export const StarBackgroundPoints = React.memo<{ points: StarBackgroundPointModel[] }>(
  function ({ points }) {
    function renderPoint(starBackgroundPoint: StarBackgroundPointModel) {
      return (
        <StyledPoint
          className={`point-with-movement-0${random(1, 4)}`}
          key={`point-${starBackgroundPoint.id}`}
          $position={starBackgroundPoint.$position}
          id={starBackgroundPoint.id}
          color={starBackgroundPoint.color}
          opacity={starBackgroundPoint.opacity}
        />
      )
    }

    return (
      <>
        {points.map(renderPoint)}
      </>
    )
  }
);
