import styled, { keyframes } from "styled-components";
import { random, sample } from "lodash";

import { IStarBackgroundPoint } from "./StartBackgroundPoints.component.model";

const pointMovement01 = keyframes`
  0% {
    transform: translate(-23px, -53px);
    animation-timing-function: ease-in;
  }

  25% {
    transform: translate(-62px, -100px);
  }

  50% {
    transform: translate(-100px, -140px);
  }

  75% {
    transform: translate(-120px, -180px);
  }

  100% {
    transform: translate(-150px, -200px);
    animation-timing-function: ease-out;
  }
`

const pointMovement02 = keyframes`
  0% {
    transform: translate(70px, 17px);
    animation-timing-function: ease-in;
  }

  25% {
    transform: translate(100px, 18px);
  }

  50% {
    transform: translate(130px, 47px);
  }

  75% {
    transform: translate(150px, 80px);
  }

  100% {
    transform: translate(170px, 100px);
    animation-timing-function: ease-out;
  }
`

const pointMovement03 = keyframes`
  0% {
    transform: translate(5px, 51px);
    animation-timing-function: ease-in;
  }

  25% {
    transform: translate(15px, 83px);
  }

  50% {
    transform: translate(40px, 106px);
  }

  75% {
    transform: translate(80px, 124px);
  }

  100% {
    transform: translate(110px, 141px);
    animation-timing-function: ease-out;
  }
`

const pointMovement04 = keyframes`
  0% {
    transform: translate(87px, -42px);
    animation-timing-function: ease-in;
  }

  25% {
    transform: translate(88px, -50px);
  }

  50% {
    transform: translate(95px, -98px);
  }

  75% {
    transform: translate(102px, -109px);
  }

  100% {
    transform: translate(111px, -120px);
    animation-timing-function: ease-out;
  }
`

const getRandomAnimation = () =>
  sample([pointMovement01, pointMovement02, pointMovement03, pointMovement04])

export const StyledPoint = styled.span.attrs<IStarBackgroundPoint>(starBackgroundPoint => ({
  style: {
    left: starBackgroundPoint.$position.x,
    top: starBackgroundPoint.$position.y,
    backgroundColor: starBackgroundPoint.color,
    opacity: starBackgroundPoint.opacity,
  }
}))`
  position: fixed;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  z-index: -1;
  animation: ${() => getRandomAnimation()} ${() => random(7, 13)}s linear infinite alternate;
`
