import { sample } from "lodash";

export enum AppColorsTitle {
  Green     = "green",
  DarkBlue  = "dark-blue",
  Grey      = "grey",
  White     = "white"
}

export class AppColors {
  static colors: {
    title: AppColorsTitle;
    value: string;
  }[] = [
    {
      title: AppColorsTitle.Green,
      value: '#0EE6B7'
    },
    {
      title: AppColorsTitle.DarkBlue,
      value: '#2A376C'
    },
    {
      title: AppColorsTitle.Grey,
      value: '#DDDDDD'
    },
    {
      title: AppColorsTitle.White,
      value: '#FFFFFF'
    }
  ]

  static getRandomColor () {
    return sample(AppColors.colors);
  }

  static getColorByTitle (title: AppColorsTitle) {
    return AppColors.colors.find(color => color.title === title);
  }
}
