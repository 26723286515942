import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { MainScreen } from 'screens/Main/Main.screen'

import { Navbar } from 'components/Navbar/Navbar.component'
import { LanguageSelector } from 'components/LanguageSelector/LanguageSelector.component'
import { StarBackground } from 'components/StarBackground/StarBackground.component'

function App () {
  return (
    <>
      <StarBackground />

      <BrowserRouter>
        <Navbar />
        <LanguageSelector />

        <Routes>
          <Route path='/'>
            <Route index Component={() => <MainScreen />} />
          </Route>

          <Route path='*' Component={() => <Navigate to='/' />} />
        </Routes>

      </BrowserRouter>
    </>
  )
}

export default App
