import { AppBreakpoints, AppDevices } from "config/styles/breakpoints.config";
import styled from "styled-components";

import arrow from "assets/images/icons/arrow.png";

export const StyledMainWorkHistory = styled.section`
`;

export const StyledMainWorkHistoryTitle = styled.h3`
  font-weight: 700;
  font-size: 2rem;
  color: white;
`;

export const StyledMainWorkHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  gap: 1rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Tablet)} {
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-bottom: 2rem;

    &::-webkit-scrollbar {
      width: 10px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: #FFFFFF66;
      background-clip: content-box;
    }
  }
`;

export const StyledMainWorkHistoryItem = styled.div`
  flex: none;
  scroll-snap-align: start;
  width: 100%;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Tablet)} {
    width: 350px;
  }
`;

export const StyledMainWorkHistoryItemContent = styled.div`
  margin: 0rem 1rem 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  h5 {
    font-size: 1rem;
    font-weight: 700;
  }

  p {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: justify;
  }

  b {
    font-weight: 600;
  }
`;

export const StyledMainWorkHistoryItemContentImg = styled.div<{ $img: string }>`
  ${({ $img }) => `
    display: block;
    background: url(${$img});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    border-radius: 5px;
    margin: 1rem 0rem;
    border: 1px solid white;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0px; left: 70%;
      width: 100%; height: 100%;
      transform: skewX(-45deg);
      background: #FFFFFF33;
    }
  `}
`;

export const StyledWorkHistoryTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin: 1rem 0rem 0rem;

  ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Tablet)} {
    margin-top: auto;
  }
`;

export const StyledWorkHistoryTag = styled.span`
  color: white;
  font-size: 0.7rem;
  background-color: #1B2330;
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
  text-wrap: nowrap;
`;

export const StyledWorkHistoryButtonArrow = styled.img.attrs({
  src: arrow,
  alt: "arrow icon"
})`
  display: inline-block;
  width: 60px;
  margin-left: 1rem;
  transition: all .3s ease;
  transform: translateX(-100px);
  opacity: 0;
  cursor: pointer;
`;

export const StyledWorkHistoryButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  color: #0EE6B7;
  cursor: pointer;
  margin: 0.5rem 0.5rem 0rem;
  padding: 0rem;

  &:hover {
    ${StyledWorkHistoryButtonArrow} {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;

