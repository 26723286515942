import { uniqueId } from "lodash";

import { Position } from "models/Point.model";
import { BaseClass } from "utils/baseclass";

export interface IStarBackgroundBar {
  $position: Position;
  $rotation: number;
  width: number;
}

export class StarBackgroundBarModel extends BaseClass<IStarBackgroundBar>() {
  public readonly id;

  constructor (data: IStarBackgroundBar) {
    super(data);
    this.id = uniqueId();
  }
}
