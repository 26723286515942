import React from 'react';

import { Position } from 'models/Point.model';

export const useMousePosition: () => Position = () => {
  const [
    mousePosition,
    setMousePosition
  ] = React.useState<Position>(new Position({ x: 0, y: 0}));

  React.useEffect(() => {
    const updateMousePosition = ev => {
      setMousePosition(new Position({ x: ev.clientX, y: ev.clientY }));
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};
