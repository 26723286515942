import { AppBreakpoints, AppDevices } from 'config/styles/breakpoints.config'
import styled from 'styled-components'

export const StyledBrowserBoxContainer = styled.div<{ $faded: boolean }>`
  ${({ $faded }) => `
    --background: #171A1BBB;
    --faded-background: linear-gradient(#171A1BBB, #171A1B00);

    background: ${$faded ? 'var(--faded-background)' : 'var(--background);'};
    border: ${$faded ? 'none' : '1px solid #4A4A4A;'};
    
    opacity: 80%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    ${AppBreakpoints.setStyleForBreakpoint(AppDevices.Laptop)} {
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  `}
`

export const StyledBrowserBoxTopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 1rem;
  min-height: 22px;
`

export const StyledBrowserBoxTopBarButton = styled.div`
  background-color: white;
  width: 8px;
  height: 8px;
  margin: 0px 2.5px;
  border-radius: 50%;
`

export const StyledBrowserBoxTopBarButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${StyledBrowserBoxTopBarButton}:nth-child(1) {
    background-color: #FF5F56;
  }

  ${StyledBrowserBoxTopBarButton}:nth-child(2) {
    background-color: #FFBD2E;
  }

  ${StyledBrowserBoxTopBarButton}:nth-child(3) {
    background-color: #27C93F;
  }
`

export const StyledBrowserBoxTopBarTitle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-size: max(0.8rem, 0.8vw);
  color: white;
  background-color: #202226;
  border-radius: 0.5rem;
  padding: max(2px, 0.2vw) 10vw;
  margin: 0rem auto;
`

export const StyledBrowserBoxTopBarContent = styled.div`
  height: 100%;
`
